@import "base/normalize";

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background: black;
}

.stage {
  display: flex;

  svg {
    width: auto;
    height: 100vh;
    margin: auto;
    cursor: pointer;
  }
}
